import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.openModalValue.status == '0')
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _ctx.openModal,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openModal) = $event)),
        "show-close": false,
        "close-on-click-modal": false,
        width: "450px",
        title: "Invoice Actioned",
        "custom-class": "confirmation-popups"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _createTextVNode(" This Invoice has been actioned, you cannot Approve/Decline it "),
            _createElementVNode("b", null, _toDisplayString(_ctx.openModalValue.item.merchant_reference), 1)
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.openModalLoading)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('refreshInvoiceListing')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Continue")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : (_openBlock(), _createBlock(_component_el_dialog, {
        key: 1,
        modelValue: _ctx.openModal,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.openModal) = $event)),
        "show-close": false,
        "close-on-click-modal": false,
        width: "450px",
        title: _ctx.openModalValue.status == '2' ? 'Approve' : (_ctx.openModalValue.status == '3' ? 'Decline' : 'Approve with Discrepancy'),
        "custom-class": "confirmation-popups"
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, [
            _createTextVNode(" Are you sure you want " + _toDisplayString(_ctx.openModalValue.status == "2" ? "Approve" : (_ctx.openModalValue.status == "3" ? "Decline" : _ctx.openModalValue.status == '4' ? "Approve with Discrepancy" : "Update Discrepancy")) + " ", 1),
            _createElementVNode("b", null, _toDisplayString(_ctx.openModalValue.item.merchant_reference), 1)
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-light confirm-popup-cancel",
              ref: "policyNoRef",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancelModal', _ctx.openModalValue)))
            }, "Cancel", 512),
            (!_ctx.openModalLoading)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('proceedModal', _ctx.openModalValue)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Proceed")
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_el_button, {
                  key: 1,
                  type: "primary",
                  loading: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Loading")
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "title"]))
}